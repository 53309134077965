import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS } from '../../constants'

export const styles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    ['& > div']: {
      marginBottom: '4%',
      width: '48%',
      height: 0,
      paddingBottom: '47%',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      ['& > div']: {
        width: '48%',
      },
    },
  },

  spaceCards: {
    justifyContent: 'space-between',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      justifyContent: 'flex-start',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      padding: '0px',
    },
  },

  centerCards: {
    justifyContent: 'center',
  },

  spinnerContainer: {
    minHeight: 280,
  },

  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    container: {
      ['& > div']: {
        backgroundColor: COLORS.whiteStain,
        marginLeft: '1.6%',
        marginBottom: '2%',
        width: '23%',
        height: 0,
        paddingBottom: '23%',

        [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
          width: '30.825%',
          height: 0,
          paddingBottom: '30.825%',
        },
      },
    },
  },
}))
