import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from './styles'
import { CircularProgress } from '@material-ui/core'
import { COLORS } from './../../constants'

interface BlockUserInterfaceProps {
  t: TFunction
}

const BlockUserInterface = ({ t }: BlockUserInterfaceProps) => {
  const classes = styles()

  return (
    <div className={classes.body}>
      <CircularProgress style={{ color: COLORS.black }} size={60} />
    </div>
  )
}
export default withTranslation()(BlockUserInterface)
