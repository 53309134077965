import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS, WIDTH_DIMENSIONS } from '../constants'

export const styles = makeStyles(theme => ({
  sectionContainer: {
    width: '100%',
    overflow: 'hidden',
    paddingTop: 100,
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      width: '90%',
      margin: '0 auto',
      paddingTop: 120,
      paddingBottom: 120,
    },
  },
  centered: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      textAlign: 'center',
    },
  },
  flexOrder: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 25,
  },
  brandsListSection: {
    position: 'relative',
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  brandsListContainer: {
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      width: '65%',
    },
  },
  brandsListTitle: {
    textAlign: 'right',
    width: '95%',
    margin: '0 auto 30px 20px',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '70%',
      margin: '0 auto 30px 20px',
    },
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      width: '30%',
      margin: '0 auto 30px 10px',
    },
  },
  addChirpyestButton: {
    position: 'absolute',
    bottom: -95,
    right: '5%',
    width: '50%',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'static',
      display: 'block',
    },
  },
  subtitleSentence: {
    fontSize: '24px',
    lineHeight: '34px',
    letterSpacing: 'normal',
  },
  landingSections: {
    height: 400,
    padding: '0 19%',
    display: 'flex',
    flexDirection: 'column',
    [`&:nth-of-type(odd)`]: {
      background: COLORS.whiteStain,
    },
    [`&:nth-child(1)`]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    [`&:nth-child(2)`]: {
      //   justifyContent: 'center',
      //   alignItems: 'start',
    },
    [`&:nth-child(3)`]: {
      justifyContent: 'center',
      alignItems: 'end',
      [`& > p`]: {
        // width: '65%',
        textAlign: 'right',
        [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
          //   width: '75%',
        },
        [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
          //   width: '100%',
          textAlign: 'center',
        },
      },
    },
    [`&:nth-child(4)`]: {
      justifyContent: 'center',
      alignItems: 'start',
    },
    [`&:nth-child(5)`]: {
      justifyContent: 'center',
      alignItems: 'end',
      [`& > p`]: {
        width: '80%',
        textAlign: 'right',
        [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {},
        [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
          textAlign: 'center',
        },
      },
    },
    [`&:nth-child(6)`]: {
      justifyContent: 'center',
      alignItems: 'start',
    },
    [`&:nth-child(8)`]: {
      background: COLORS.whiteStain,
      justifyContent: 'center',
      alignItems: 'center',
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        height: 450,
      },
    },
    [`& .carousel-root`]: {
      margin: 0,
    },
    [`& .carousel .control-dots`]: {
      bottom: -55,
      [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
        bottom: -35,
      },
      [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
        bottom: -26,
      },
    },
    [`& .carousel .slide img`]: {
      width: 100,
      height: 100,
      marginBottom: 15,
    },
    [`& .carousel.carousel-slider`]: {
      overflow: 'inherit',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      padding: '0 9%',
      height: 370,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      padding: '0 5%',
      textAlign: 'center',
      justifyContent: 'center !important',
      alignItems: 'center !important',
    },
  },
  buttonStyles: {
    width: '25%',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: '39%',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100%',
    },
  },
  buttonWidthStyle: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '100% !important',
    },
  },
  outlineButtonWidthStyle: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      width: '38.5% !important',
    },
  },
  buttonOutlineStyles: {
    width: '25%',
    backgroundColor: COLORS.white,
    margin: 0,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      width: '39%',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '100%',
    },
  },
  headingSmall: {
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    fontSize: '16px !important',
    lineHeight: '26px !important',
    letterSpacing: 'normal',
    padding: '0 6%',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '16px !important',
      lineHeight: '26px !important',
      padding: 0,
    },
  },
  headingMedium: {
    fontFamily: FONTS.Schnyder.SchnyderMLight + ' !important',
    fontSize: '40px !important',
    lineHeight: '45px !important',
    letterSpacing: 'normal',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '36px !important',
      lineHeight: '40px !important',
    },
  },
  headingMediumSmall: {
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '30px !important',
      lineHeight: '34px !important',
    },
  },
  quote: {
    fontFamily: FONTS.Schnyder.SchnyderMLightItalic + ' !important',
    fontSize: '40px !important',
    fontStyle: 'italic',
    lineHeight: '45px !important',
    letterSpacing: 'normal',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '36px !important',
      lineHeight: '40px !important',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      overflowWrap: 'anywhere',
    },
  },
  headingLarge: {
    fontFamily: FONTS.Schnyder.SchnyderMLight + ' !important',
    fontSize: '60px !important',
    lineHeight: '68px !important',
    letterSpacing: 'normal',
    marginBottom: '20px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '45px !important',
      lineHeight: '50px !important',
    },
  },
  paragraphStyle: {
    fontSize: '24px !important',
    lineHeight: '34px !important',
    letterSpacing: 'normal',
    marginBottom: '25px !important',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      fontSize: '18px !important',
      lineHeight: '28px !important',
    },
  },
  subtitleBrandsSentence: {
    fontSize: '24px',
    lineHeight: '34px',
    letterSpacing: 'normal',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      textAlign: 'center',
      fontSize: '18px',
      marginBottom: 20,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      width: '93%',
    },
  },
  brandsHeading: {
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      textAlign: 'center',
      fontSize: '36px',
      marginBottom: 20,
    },
  },
  mobileBackgroundStyle: {
    width: '100%',
    margin: '0 auto',
    maxWidth: WIDTH_DIMENSIONS.max,
    padding: '40px 0 154px',
    [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
      padding: '40px 1% 80px',
    },
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      backgroundColor: COLORS.white,
      padding: '0px 0px',
      width: '95%',
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      padding: '40px 0 100px',
    },
  },
  brandsListStyle: {
    paddingTop: 80,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {},
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      paddingTop: 0,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      paddingTop: 45,
    },
  },
  addChirpyestButtonStyle: {
    position: 'absolute',
    bottom: -95,
    right: '5%',
    width: '50%',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'static',
      display: 'block',
      marginLeft: 'auto',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      right: 0,
      transform: 'translate(-52%, -50%)',
      left: '50%',
      width: '30.6%',
      bottom: -107,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      right: 0,
      transform: 'translate(-50%, -50%)',
      left: '50%',
      width: '90%',
      bottom: -107,
    },
  },
  waitingFor: {
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      marginBottom: 90,
    },
  },
  quoteImage: {
    width: 100,
    height: 100,
  },
  link: {
    textDecoration: 'none',
    color: COLORS.black,
  },
  whiteLink: {
    textDecoration: 'none',
    color: COLORS.white,
  },
  productsContainer: {
    fontFamily: FONTS.Graphik.GraphikLight,
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',
    maxWidth: WIDTH_DIMENSIONS.max,
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      //   width: '90%',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      //   width: '94%',
    },
  },
  headingMargin: {
    marginBottom: 20,
    letterSpacing: 'normal',
    lineHeight: '45px',
    fontFamily: FONTS.Schnyder.SchnyderLLight,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      lineHeight: '40px !important',
      fontSize: '36px !important',
    },
  },
  tabPanelSection: {
    maxWidth: 700,
    margin: '0px auto',
    [`& .MuiAccordionSummary-content > p`]: {
      fontFamily: FONTS.Graphik.GraphikRegular,
      letterSpacing: 'normal',
      lineHeight: '23px',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      margin: 0,
    },
    [`& .MuiPaper-root`]: {
      backgroundColor: 'transparent',
    },
  },
  extensionSections: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4.5% 15% 3%',
    [`&:nth-of-type(even)`]: {
      background: COLORS.whiteStain,
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      padding: '10% 6%',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      padding: '10% 4%',
    },
  },
  videoSections: {
    display: 'flex',
    height: 500,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      flexDirection: 'column-reverse',
      height: 'auto',
    },
  },
  leftVideoSection: {
    display: 'flex',
    width: '50%',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
    padding: '0 2.5%',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      padding: '50px 10%',
      width: '100%',
    },
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      padding: '50px 5%',
      textAlign: 'center',
    },
  },
  rightVideoSection: {
    width: '50%',
    height: '100%',
    [`& video`]: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      height: 450,
      width: '100%',
    },
  },
  centerHeading: {
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      textAlign: 'center',
    },
  },
  seoHeading: {
    fontSize: '14px !important',
    fontFamily: 'Graphik Light !important',
    letterSpacing: '0.00938em !important',
    lineHeight: '1.64 !important',
    marginBottom: '0px !important',
    fontWeight: 400,
    color: COLORS.lightGrey,
  },
}))
