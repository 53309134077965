import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(theme => ({
  body: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
