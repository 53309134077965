import * as React from 'react'
import { styles } from './styles'
import Spinner from './../spinner'

interface BrandsListProps {
  children?: React.ReactNode
  isLoading?: boolean
  areCardsCentered?: boolean
}

const NewBrandsList = ({
  children,
  isLoading,
  areCardsCentered,
}: BrandsListProps) => {
  const classes = styles()

  const cardsSpacing = areCardsCentered
    ? classes.centerCards
    : classes.spaceCards

  return (
    <div className={`${classes.container} ${cardsSpacing}`}>
      {isLoading ? (
        <div className={classes.spinnerContainer}>
          <Spinner />
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  )
}

export default NewBrandsList
